import { configureStore } from "@reduxjs/toolkit";
import { usersSlice } from "./userSlice";
import { accountSlice } from "./accountSlice";
import { appointmentsSlice } from "./appointmentsSlice";
import { shopsSlice } from "./shopsSlice";
import { businessServicesSlice } from "./businessServicesSlice";
import { employeesSlice } from "./employeesSlice";
import { availabilitiesSlice } from "./availabilitiesSlice";
import { clientsSlice } from "./clientsSlice";
import { schedulesSlice } from "./schedulesSlice";
import { onboardingSlice } from "./onboardingSlice";
import { salesSlice } from "./salesSlice";
import { weeklySchedulesSlice } from "./weeklySchedulesSlice";

export const store = configureStore({
  reducer: {
    user: usersSlice.reducer,
    account: accountSlice.reducer,
    onboarding: onboardingSlice.reducer,
    shops: shopsSlice.reducer,
    appointments: appointmentsSlice.reducer,
    businessServices: businessServicesSlice.reducer,
    employees: employeesSlice.reducer,
    availabilities: availabilitiesSlice.reducer,
    clients: clientsSlice.reducer,
    schedules: schedulesSlice.reducer,
    weeklySchedules: weeklySchedulesSlice.reducer,
    sales: salesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UserState}
export type AppDispatch = typeof store.dispatch;
