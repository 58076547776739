import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";
import { Schedule } from "./schedulesSlice";

export interface WeeklySchedulesSliceState {
  [employeeId: number]: {
    [date: string]: Schedule[];
  };
}

const initialState = {} as WeeklySchedulesSliceState;

export const getWeeklySchedules = createAsyncThunk(
  "schedules/getWeeklySchedules",
  async (
    { employeeId, date }: { employeeId: number; date: Date },
    thunkAPI
  ) => {
    console.log("getWeeklySchedules", employeeId, date);
    try {
      const { data } = await http.get("/v1/schedules/weekly", {
        params: {
          schedules: {
            employeeId,
            date,
          },
          snakeCase: true,
        },
      });
      return { employeeId, data };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// export const createWeeklySchedules = createAsyncThunk(
// );

// export const updateWeeklySchedules = createAsyncThunk(
// );

// export const deleteWeeklySchedules = createAsyncThunk(
// );

export const weeklySchedulesSlice = createSlice({
  name: "weeklySchedules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWeeklySchedules.fulfilled, (state, action) => {
      const { employeeId, data } = action.payload as any;
      if (data.length > 0) {
        if (!state[employeeId]) {
          state[employeeId] = {};
        }
        data.forEach((schedule: Schedule) => {
          const date = schedule.date;

          if (!state[employeeId][date]) {
            state[employeeId][date] = [];
          }
          state[employeeId][date].push(schedule);
        });
      }
    });
  },
});

export const selectWeeklySchedules = (state: RootState) =>
  state.weeklySchedules;
